import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "react-multi-carousel/lib/styles.css";

import { Container } from "@components/global";
import ReservationBox from "@components/common/ReservationBox";

const HeaderGdansk = ({ state }) => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);
  return (
    <HeaderWrapper>
      <SlideImg
        fluid={
          data.allImageSharp.edges.find((element) => {
            // Match string after final slash
            return element.node.fluid.src
              .split("/")
              .pop()
              .includes("lewe-home");
          }).node.fluid
        }
      />
      <PadlessContainer>
        <Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SlideTitleH1>
              Najtańsza Wypożyczalnia Samochodów z LPG
            </SlideTitleH1>
            <SlideContent>
              <strong>
                Najtańsza Wypożyczalnia samochodów Rent Me Car Warszawa
              </strong>{" "}
              to jedyna <strong>wypożyczalnia aut Warszawa</strong>, która
              posiada w swojej ofercie samochody z instalacją LPG. Dzięki
              niskiej cenie za <strong>wynajem samochodu</strong> oraz
              instalacji LPG redukujesz koszta najmu auta do minimum!{" "}
              <strong>
                (koszt przejechania 100 km to jedyne 12 zł). Wypożyczamy
                samochody bez kaucji
              </strong>{" "}
              oraz <strong>podstawiamy na wskazany adres</strong>. Realizujemy
              również <strong>wynajem długoterminowy samochodów</strong>.
            </SlideContent>
          </div>
          <ReservationBoxWrapper>
            <ReservationBox state={state} />
          </ReservationBoxWrapper>
        </Grid>
      </PadlessContainer>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.color.white};
  color: #222;
  /* margin-top: 70px; */
  position: relative;
  @media (min-width: 991px) {
    height: 500px;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    /* margin-top: 128px; */
  }
`;

const SlideImg = styled(Img)`
  max-height: 600px;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const SlideTitleH1 = styled.h1`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.large};
  z-index: 5;
  background: ${(props) => props.theme.color.black.regular};
  color: white;
  padding: 10px 15px;
  text-align: center;
  width: auto;
  margin: 0;

  @media (min-width: 891px) {
    ${(props) => props.theme.font_size.xlarge};
  }
  @media (min-width: 991px) {
    border-radius: 4px;
    max-width: 500px;
    align-self: center;
    justify-self: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  @media (min-width: 1050px) {
    justify-self: center;
  }
`;

const SlideContent = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.small};
  z-index: 5;
  background: ${(props) => props.theme.color.black.regular};
  color: white;
  padding: 10px 15px;
  text-align: center;
  width: auto;
  margin: 0;
  margin-top: -1rem;

  @media (min-width: 891px) {
    ${(props) => props.theme.font_size.small};
  }
  @media (min-width: 991px) {
    border-radius: 4px;
    max-width: 500px;
    align-self: center;
    justify-self: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 1rem;
  }
  @media (min-width: 1050px) {
    justify-self: center;
  }
`;

const Grid = styled.div`
  width: 100%;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  align-items: stretch;
  align-content: stretch;
  @media (min-width: 991px) {
    align-items: center;
    align-content: center;
    justify-items: center;
    grid-template-columns: 1fr 450px;
    height: 480px;
  }
`;

const ReservationBoxWrapper = styled.div`
  width: 100%;
  z-index: 10;

  @media (max-width: 990px) {
    > div {
      box-shadow: none;
      border-radius: 0;
    }
  }
  @media (min-width: 991px) {
    grid-column: 2;
    margin: 40px 0;
    align-self: center;
    padding-right: 10px;
  }
`;

const PadlessContainer = styled(Container)`
  padding: 0;
`;

export default HeaderGdansk;
