import React, { useState } from "react";

import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import HeaderIndex from "@sections/Header";
import Features from "@sections/Features";
import CarsCarousel from "@common/CarsCarousel";
import Footer from "@sections/Footer";
import { Section, Container } from "@components/global";
import styled from "styled-components";
import FaqWarszawa from "../components/sections/FaqWarszawa";

const StandaloneText = styled.div`
  max-width: 991px;
  margin: 0 auto;
  padding: 0 1rem;
  & p {
    margin-bottom: 1rem;
    & strong {
      color: ${(props) => props.theme.color.black.regular};
    }
  }
  margin-bottom: 2rem;
`;

const Header = styled.h2`
  margin-top: 0.5rem;
  text-align: center;
`;

const HeaderH1 = styled.h1`
  margin-top: 0.5rem;
  font-size: 26px;
  text-align: center;
  padding: 0 1rem;
`;

const Karteczka = styled.section`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 250px;

  & > div {
    border-radius: 0.5rem;
    height: 100%;
    min-height: 250px;
    & > div {
      border-radius: 0.5rem 0.5rem 0 0;
      max-height: 150px;
      z-index: 1;
      /* filter: opacity(0.14) saturate(0); */
      background: #eaeaea;
    }
    & > h3 {
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
      padding-top: 0.6rem;
      margin: 0;
      line-height: 1.4;
      font-size: 16px;
      text-align: center;
      font-family: ${(props) => props.theme.font.primary};
      z-index: 2;
      color: #222;
      background: #f4f4f4;
    }
    & ul > li {
      margin: 0.5rem 0;
    }
    & ul > li:first-child {
      margin: 0;
    }
    & ul > li:last-child {
      margin: 0;
    }
    & p,
    & ul {
      position: relative;
      font-size: 14px;
      line-height: 1.4;
      z-index: 2;
      color: #222;
      background: #f4f4f4;
      border-radius: 0 0 0.5rem 0.5rem;
      padding: 0.5rem;
      padding-top: 0.4rem;
      padding-bottom: 0.7rem;
      margin-top: 0;

      & strong {
        display: inline;
        font-weight: bold;
        color: ${(props) => props.theme.color.black.regular};
      }
    }
  }
`;

const Karteczki = styled(({ data, ...rest }) => {
  const [open, setOpen] = useState(null);
  return (
    <section {...rest}>
      <Karteczka
        open={open === 1}
        onClick={() => {
          setOpen(1);
        }}
      >
        <div>
          <Img
            loading="eager"
            fluid={data.bezKaucji.childImageSharp.fluid}
            alt="Tani wynajem auta Warszawa"
          />
          <h3>Tani wynajem auta Warszawa</h3>
          <p>
            Wypożyczalnia samochodów w Warszawie jest czynna 24 godziny, przez
            siedem dni w tygodniu.{" "}
            <strong>Wynajmujemy samochody na doby</strong>, a nawet miesiące.
            Nasza nowa flota samochodów oferuje bezawaryjne i nowoczesne auta w
            przystępnej cenie. Rent Me Car podstawia auto na wskazany przez
            Ciebie adres. Fachowa obsługa pomoże wybrać odpowiedni model auta do
            Twoich potrzeb.Najtańsza wypożyczalnia samochodów z gazem działa na
            terenie całej <strong>Warszawy</strong>, możemy dostarczyć auta pod
            wskazany adres lub możesz je odebrać osobiście w naszej placówce
            przy ulicy <strong>Rogalskiego 10, 03-982 Warszawa</strong>.
          </p>
        </div>
      </Karteczka>
      <Karteczka
        open={open === 2}
        onClick={() => {
          setOpen(2);
        }}
      >
        <div>
          <Img
            loading="eager"
            fluid={data.wynajemDlugoterminowy.childImageSharp.fluid}
            alt="Wynajem długoterminowy samochodów Warszawa"
          />
          <h3>Wynajem długoterminowy samochodów Warszawa</h3>
          <p>
            Jako Rent Me Car prowadzimy również wynajem długoterminowy aut na
            terenie całego kraju. Czas trwania umowy dobieramy zgodnie z
            oczekiwaniami klienta,{" "}
            <strong>od miesiąca, a na 5 latach kończąc</strong>. Dzięki{" "}
            <strong><a href="https://mamfach.pl/oferty">instalacji LPG w naszych samochodach</a></strong> redukujesz
            koszta swojego budżetu do minimum.{" "}
            <strong>Wynajem długoterminowy samochodów z LPG</strong> to dobra
            alternatywa dla zakupu i utrzymania drogich samochodów dla firm oraz
            klientów indywidualnych. Z nami nie martwisz się o awarie, koszty
            eksploatacyjne, naprawy, wymiany opon oraz koszty ukryte, związane z
            utrzymaniem auta.{" "}
            <strong>
              Po Twojej stronie jest tylko paliwo i płyn do spryskiwaczy.
            </strong>{" "}
            W celu zapoznania się z ofertą{" "}
            <strong>wynajem długoterminowy aut</strong> zachęcamy do kontaktu
            telefonicznego. Nasi konsultanci przedstawią najkorzystniejszą
            ofertę dobraną do Twoich potrzeb.
          </p>
        </div>
      </Karteczka>
      <Karteczka
        open={open === 3}
        onClick={() => {
          setOpen(3);
        }}
      >
        <div>
          <Img
            loading="eager"
            fluid={data.one.childImageSharp.fluid}
            alt="Wypożyczalnia samochodów z gazem"
          />
          <h3>Wypożyczalnia samochodów z gazem</h3>
          <p>
            <strong>
              Najtańsza wypożyczalnia samochodów z LPG Rent Me Car
            </strong>{" "}
            to jedyna wypożyczalnia aut w kraju, która w swojej ofercie ma
            samochody z instalacją gazową. Dzięki zastosowaniu instalacji LPG
            najnowszej generacji możemy zapewnić naszym klientom nowe samochody,
            których koszty eksploatacji zamykają się w 20zł na 100km. Rent Me
            Car Warszawa to jedyna wypożyczalnia auto w kraju, gdzie nie tylko
            tanio wypożyczysz samochód, ale również będziesz nim tanio jeździł.
          </p>
        </div>
      </Karteczka>
    </section>
  );
})`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  @media (min-width: 991px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "other" }, name: { eq: "doh2" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      bezKaucji: file(
        sourceInstanceName: { eq: "other" }
        name: { eq: "bez-kaucji" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wynajemDlugoterminowy: file(
        sourceInstanceName: { eq: "other" }
        name: { eq: "wynajem-dlugoterminowy" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      instalacjaLpg: file(
        sourceInstanceName: { eq: "other" }
        name: { eq: "instalacja-lpg" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gdansk1: file(
        sourceInstanceName: { eq: "carousel" }
        name: { eq: "gdansk-1" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gdansk2: file(
        sourceInstanceName: { eq: "carousel" }
        name: { eq: "gdansk-2" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gdansk3: file(
        sourceInstanceName: { eq: "carousel" }
        name: { eq: "gdansk-3" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      one: file(sourceInstanceName: { eq: "carousel" }, name: { eq: "one" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout
      seo={{
        url: "https://www.najtansza-wypozyczalnia-samochodow.pl",
        title: `Najtańsza Wypożyczalnia Samochodów LPG Warszawa | wynajem aut LPG bez kaucji RENT ME CAR 🚙`,
        description: `Wypożyczalnia samochodów RENT ME CAR 🚙. Wynajem nowych aut z LPG bez kaucji. Podstawiamy na miejsce 🎯. Wynajem długoterminowy samochodów z LPG.`,
      }}
    >
      <TopHeader />
      <HeaderIndex state={location && location.state ? location.state : null} />

      <Section style={{ paddingTop: "1rem" }}>
        <Container>
          <Header>
            <Link to="/samochody">Nasze samochody</Link>
          </Header>
        </Container>
        <CarsCarousel />
      </Section>

      <Section
        fluid
        style={{ marginTop: "-2rem", paddingTop: "0", paddingBottom: "0" }}
      >
        <HeaderH1 style={{ marginBottom: "1.5rem" }}>
          Wypożyczalnia samochodów z LPG WARSZAWA
        </HeaderH1>
        <StandaloneText>
          <p style={{ marginBottom: "1.5rem" }}>
            Wypożyczalnia samochodów RENT ME CAR zajmuje się profesjonalnym
            wynajmem samochodów osobowych. OFERTA:
          </p>

          <Karteczki data={data} />
        </StandaloneText>
      </Section>
      <Features />

      <FaqWarszawa />

      <Section fluid>
        <StandaloneText style={{ marginTop: "0", marginBottom: "0" }}>
          <p style={{ marginTop: "0" }}>
            W skład naszej floty wchodzi szeroki przekrój pojazdów. Zaczynając
            od malutkiego Volkswagena UP, Skodę Fabię, poprzez rodzinną i
            komfortową Skodę Superb, a na sportowym Fordzie Mustangu 5.0 GT
            kończąc.
          </p>
          <p>
            Naszą ofertę kierujemy zarówno do{" "}
            <strong>osób prywatnych jak i firm</strong>. W celu rezerwacji
            samochodu należy wypełnić formularz rezerwacji, a następnie czekać
            na kontakt z naszej strony. Samochód zarezerwować można również{" "}
            <strong>telefonicznie</strong>, kontaktując się z naszym działem
            obsługi klienta.{" "}
            <strong>
              Wypożyczamy auta bez kaucji i podstawiamy na adres wskazany przez
              klienta, lotnisko, centrum miasta bądź dworzec PKP
            </strong>
            . Nawiązujemy <strong>wynajem długoterminowy</strong> z klientami
            indywidualnymi, firmami i małymi przedsiębiorcami.{" "}
            <strong>Wynajmujemy pojazdy zastępcze z OC sprawcy</strong> oraz
            pomagamy dochodzić praw do odszkodowania z polisy OC. Oferujemy
            możliwość wyposażenia wynajętego pojazdu w fotelik dla dziecka,
            nawigację GPS czy uchwyt na telefon.
          </p>
          <p style={{ marginBottom: "0" }}>
            Posiadamy flotę nowych samochodów dzięki czemu klient może czuć się
            bezpiecznie i pewnie. Część naszych samochodów wyposażona jest w
            sekwencyjne instalacje LPG IV generacji, przez co koszt eksploatacji
            pojazdu jest znacznie obniżony. Wszystkie samochody mają opłacone
            ubezpieczenie OC/AC, a w okresie od 1 listopada do 30 marca
            posiadają opony zimowe. Nie stawiamy wymagań co do wieku kierowcy.
            Jesteśmy elastyczni i dbamy o swoich stałych klientów, oferującym im
            korzystne promocje i rabaty. Szanujemy swoich klientów, a naszą
            dobrą i owocną współpracę stawiamy ponad wszystko. W razie
            jakichkolwiek pytań czy wątpliwości zachęcamy skorzystać z
            przygotowanego specjalnie dla Państwa{" "}
            <strong>
              <a href="/faq">FAQ</a>
            </strong>
            , gdzie znajdują się <strong>najczęściej zadawane pytania</strong>.
            Namawiamy również do skorzystania z formularza rezerwacji lub
            kontaktu telefonicznego.{" "}
            <strong>Nie czekaj i wynajmij swój samochód już dziś!</strong>
          </p>
        </StandaloneText>
      </Section>

      <Footer />
    </Layout>
  );
};

export default IndexPage;
