import React from "react";
import styled from "styled-components";
import { renderToStaticMarkup } from "react-dom/server";
import { JsonLd } from "./../common/JsonLd";
import { Section, Container } from "@components/global";

import FaqItem from "@common/FaqItem";
import { Link } from "gatsby";

const FAQS = [
  {
    title:
      "W jakim muszę być wieku i ile lat posiadać prawo jazdy, żeby wynająć samochód?",
    content: () => (
      <>
        <p>
          Najtańsza wypożyczalnia samochodów w Warszawie realizuje najem aut dla
          osób, które ukończyły 18 lat i mają ważne prawo jazdy kategorii B.
        </p>
      </>
    ),
  },
  {
    title:
      "Jakich dokumentów oczekuje ode mnie najtańsza wypożyczalnia samochodów RENT ME CAR?",
    content: () => (
      <>
        <p>Podczas odbioru samochodu, będą sprawdzane następujące dokumenty:</p>
        <ul>
          <li>ważny dowód osobisty</li>
          <li>aktualne prawo jazdy kategorii B</li>
        </ul>
      </>
    ),
  },
  {
    title: "Czy muszę wpłacać kaucję?",
    content: () => (
      <>
        <p>
          Jeśli chcesz wynająć samochód bez kaucji, wybierz w udogodnieniach
          dodatkowe ubezpieczenie, które znosi udział własny w szkodzie na
          wynajętym pojeździe.
        </p>
      </>
    ),
  },
  {
    title: "Jakie auto mogę wypożyczyć najtaniej w Warszawie?",
    content: () => (
      <>
        <p>
          Jeśli w grę w chodzi kwestia ekonomiczna, a wielkość samochodu nie
          jest istotna, najtańszym rozwiązaniem będzie dla Ciebie wypożyczenie
          samochodu z <Link to="/samochody#segment-a">segmentu A</Link> z
          instalacją LPG.
        </p>
      </>
    ),
  },
  {
    title:
      "Czy wypożyczalnia zmieniła swoje zasady czyszczenia i dezynfekcji ze względu na COVID-19?",
    content: () => (
      <>
        <p>
          Auta po każdym zrealizowanym najmie są dokładnie czyszczone oraz
          dezynfekowane za pomocą środków na bazie alkoholu. Dodatkowo wnętrza
          pojazdów są ozonowane.
        </p>
      </>
    ),
  },
  {
    title:
      "W jakich godzinach jest czynna wypożyczalnia samochodów w Warszawie?",
    content: () => (
      <>
        <p>
          Jesteśmy czynni od godziny 8:00 do 16:00 od poniedziałku do piątku, a
          w soboty od godziny 9:00, do 13:00, aczkolwiek nasza infolinia jest
          dostępna 24h/7 dni w tygodniu, a samochód możesz odebrać i zwrócić,
          naszym pracownikom, poza godzinami pracy wypożyczalni.
        </p>
      </>
    ),
  },
];

const Blah = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
  article {
    margin: 0;
  }
  ul {
    margin-bottom: 0;
  }
`;

const Section2 = styled(Section)`
  padding: 0;
  max-width: 991px;
  margin: 0 auto;
  margin-top: 2rem;
`;

const FaqWarszawa = () => {
  return (
    <Section2 id="faq">
      <h2 style={{ marginBottom: "1.5rem", textAlign: "center" }}>
        Najczęściej zadawane pytania
      </h2>
      <JsonLd
        data={{
          "@context": "http://schema.org",
          "@type": "FAQPage",
          mainEntity: [
            ...FAQS.map(({ title, content }) => ({
              "@type": "Question",
              name: title,
              acceptedAnswer: {
                "@type": "Answer",
                text: renderToStaticMarkup(content()),
              },
            })),
          ],
        }}
      />
      <Blah>
        {FAQS.map(({ title, content }) => (
          <FaqItem collapsible title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </Blah>
    </Section2>
  );
};

export default FaqWarszawa;
